<template>
  <div id="email-change-modal" class="modal fade modal-forms" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="margin:auto" role="document">
      <!--  -->
      <div class="modal-content" id="forgot-password-modal-content" v-if="exibeConfirmacao">
        <div class="modal-header pb-0">
          <h4 class="modal-title">E-mail alterado com sucesso!</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">
            Seu e-mail foi alterado com sucesso!
          </p>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal" v-on:click="fecharModal">
            Fechar
          </button>
        </div>
      </div>
      <!--  -->
      <div class="modal-content" id="email-chance-modal-content" v-if="exibeForm">
        <div class="modal-header pb-0">
          <h4 class="modal-title text-uppercase">Atenção</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">
            Informe o novo e-mail
          </p>
          <form method="post" id="change-email-form" role="form">
            <div class="form-group mt-5">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-address-card"></i>
                </div>
                <input 
                  name="novoEmail" 
                  id="novoEmail" 
                  type="email" 
                  class="form-control input-lg" 
                  placeholder="Novo E-mail"
                  required 
                  data-parsley-minlength="6" 
                  data-parsley-type="email"
                  data-parsley-trigger="keyup" 
                  v-model="novoEmail" 
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-address-card"></i>
                </div>
                <input 
                  name="confirmaEmail" 
                  id="confirmaEmail"
                  type="email" 
                  class="form-control input-lg" 
                  placeholder="Confirme o novo e-mail"
                  required 
                  data-parsley-type="email"
                  data-parsley-trigger="keyup" 
                  v-model="confirmaEmail" 
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-lock"></i>
                </div>
                <input name="senhaAtual" type="password" class="form-control input-lg" placeholder="Senha Atual" required
                  data-parsley-minlength="6" data-parsley-equalto="#passwd" data-parsley-trigger="keyup" v-model="senhaAtual" />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-key"></i>
                </div>
                <input 
                  name="chaveSeguranca" 
                  id="chaveSeguranca"
                  type="text" 
                  class="form-control input-lg" 
                  :placeholder="[[ chaveSegurancaPergunta.key ]]"
                  required 
                  v-model="chaveSeguranca" 
                />
              </div>
            </div>
            <div v-if="errors.length">
              <ul>
                <li class="text-danger" v-for="(error, index)  in errors" v-bind:key="index">{{ error }}</li>
              </ul>
            </div>

            <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              v-on:click="alteraEmail">
              <div class="spinner-border" role="status" v-if="exibeLoading">
                <span class="sr-only"></span>
              </div>
              Alterar
            </button>
          </form>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import EventBus from '../event-bus';
import AuthServiceContext from "../services/AuthService";
import moment from "moment";

export default {
  name: "AlterarEmailModal",
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      errors: [],
      processing: false,
      alteracaoRealizada: false,
      novoEmail: "",
      confirmaEmail: "",
      senhaAtual: "",
      chaveSeguranca: "",
      chaveSegurancaPergunta: ""
    };
  },
  mounted() {
    var self = this;
    EventBus.$on('eventShowAlterarEmail', function () {
      self.show();
    });
  },
  computed: {
    exibeLoading: function () {
      return this.processing;
    },
    exibeForm: function () {
      return !this.alteracaoRealizada;
    },
    exibeConfirmacao: function () {
      return this.alteracaoRealizada;
    }
  },
  methods: {
    show: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.senhaAtual = "";
      this.novoEmail = "";
      this.confirmaEmail = "";
      this.chaveSeguranca = "";
      this.chaveSegurancaPergunta = this.chaveSegurancaPerguntas();
      $("#email-change-modal").modal("show");
    },
    fecharModal: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.senhaAtual = "";
      this.novoEmail = "";
      this.confirmaEmail = "";
      this.chaveSeguranca = "";
      this.chaveSegurancaPergunta = "";
      $("#email-change-modal").modal("hide");
    },
    alteraEmail: function (event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;

      this.errors = [];

      if (this.senhaAtual.length == 0) {
        this.errors.push("informe sua senha atual.");
      }

      if (this.novoEmail.length == 0) {
        this.errors.push("Informe seu novo e-mail.");
        this.processing = false;
      } else if (this.novoEmail != this.confirmaEmail) {
        this.errors.push("Verifique a confirmação do e-mail.");
        this.processing = false;
      } else if (!this.validEmail(this.novoEmail)) {
        this.errors.push(
          "E-mail em formato incorreto, verifique."
        );
        this.processing = false;
      } else if (this.chaveSeguranca != this.chaveSegurancaPergunta.value) {
        this.errors.push(this.chaveSegurancaPergunta.key);
        this.processing = false;
      }

      if (this.errors.length == 0) {
        let acessoApi = new ApiPortalAutoAtendimento.AcessoApi();
        let userData = this.authService.userData();

        acessoApi.alterarEmail(userData.Usuario, this.senhaAtual, this.novoEmail, (error, data, response) => {
          if (!error) {
            this.alteracaoRealizada = true;
          } else {
            if (response && response.text) {
              try {
                let listOfErrors = JSON.parse(response.text);
                listOfErrors.forEach(error => {
                  this.errors.push(error.message);
                });
              } catch (e) {
                this.errors.push(
                  "Erro ao solicitar alteração do e-mail. Tente novamente."
                );
                this.processing = false;
              }
            } else {
              this.errors.push(
                "Erro ao solicitar alteração do e-mail. Tente novamente."
              );
              this.processing = false;
            }
          }
        });
      }
    },
    validEmail: function (value) {
      let re = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/g;
      return re.test(value);
    },
    chaveSegurancaPerguntas: function () {
      let userData = this.authService.userData();
      let chaveSegurancaPerguntas = [
        { key : "Informe o 1º nome de sua mãe", value: userData.NomeMae.substring(0, userData.NomeMae.indexOf(' ')) },
        { key : "Informe os 3 últimos dígitos do seu CPF", value: userData.CpfCnpj.substring(userData.CpfCnpj.length - 3) },
        { key : "Informe seu ano de nascimento", value: moment(userData.DataNascimento).year() }
      ];
      let i = Math.floor(Math.random() * chaveSegurancaPerguntas.length);

      return chaveSegurancaPerguntas[i];
    },
  }
};
</script>