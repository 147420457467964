<template>
  <div id="page-top">

  <!-- Page Wrapper -->
  <div id="wrapper">

    <!-- Topbar -->
    <head id="top-navbar" class="navbar navbar-expand navbar-light bg-white topbar static-top shadow">

      <div class="top-left">
      
      <!-- Sidebar Toggle (Topbar) -->
      <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle">
        <i class="fa fa-bars"></i>
      </button>

      <!-- Sidebar - Brand -->
      <div id="brand-wrapper">
        <router-link class="sidebar-brand align-items-center justify-content-center" to="/meuscontratos">
            <div class="sidebar-brand-logo"><h1><img class="mw-100" src="img/logo-painel.png" title="A plataforma digital do Banco Digimais" alt="Digi+CDC" /></h1></div>
            <div class="sidebar-brand-logo"><h1><img class="mw-100" src="img/logo-mobile-painel.png" title="A plataforma digital do Banco Digimais" alt="Digi+CDC" /></h1></div>
        </router-link>
      </div>

      </div>

      <div class="top-right">

        <div id="countdown" class="my-2">
          <div class="text">
            <em>Tempo de acesso restante<br/></em><span class="time font-weight-bolder">{{ sessionTime }}  </span>
          </div>
          <div class="icon">
            <img src="img/icon-timer.png">
          </div>
        </div>
        <!-- Topbar Navbar -->
        <ul id="user-nav" class="navbar-nav">
          <!-- Nav Item - User Information -->
          <li class="dropdown">
            <a class="nav-link dropdown-toggle" href="#dropdown-menu" id="userDropdown" role="button" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
              <div class="text">
                Olá <br>
                <span>{{ this.usuario.nome }}</span>
              </div>
              <div class="icon">
                <img src="img/icon-user.png">
              </div>
              <!--<img class="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60">-->
            </a>
            <!-- Dropdown - User Information -->
            <div id="dropdown-menu" class="dropdown-menu dropdown-menu-right shadow collapse" aria-labelledby="userDropdown">
              <span class="dropdown-header text-center">Versão: {{ this.siteVersion }}</span>
              <div class="dropdown-divider"></div>
              <span class="dropdown-header">{{ this.usuario.nomeCompleto }}</span>
              <span class="dropdown-header" v-if="ehLoginCompleto">CPF: {{ this.cpfcnpj }} </span>
              <span class="p-1 px-4 d-block">{{ this.usuario.email }}</span>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item abre-modal" href="#" v-on:click="showAlterarSenha" v-if="hideItemMenu && !ehLoginCompleto" >
                <i class="fas fa-lock fa-sm fa-fw mr-2 text-gray-400"></i>
                Alterar senha
              </a>
              <a class="dropdown-item abre-modal" href="#" v-on:click="showAlterarEmail" v-if="hideItemMenu && !ehLoginCompleto" >
                  <i class="fas fa-address-card fa-sm fa-fw mr-2 text-gray-400"></i>
                  Alterar e-mail
                </a>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Sair
              </a>
            </div>
          </li>

        </ul>

      </div>

    </head>
    <!-- End of Topbar -->

    <LogoutModal></LogoutModal>

    <AlterarSenhaModal></AlterarSenhaModal>
    <AlterarEmailModal></AlterarEmailModal>


    <!-- Sidebar -->
    <div class="toggled bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <ul class="navbar-nav">
      <!-- Nav Item - Dashboard -->
      <li class="nav-item"
          v-bind:class="[{ active: this.$router.currentRoute.name == 'meuscontratos' }]"
          v-if="!this.ehLoginCompleto && hideItemMenu"
      >
          <router-link class="nav-link" to="/meuscontratos">
              <img src="img/icon-menu-contratos.png">
              <span>Meus Contratos</span>
          </router-link>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="ultimos-boletos.html">
          <i class="fas fa-file-invoice-dollar"></i>
          <span>Últimos Boletos</span></a>
      </li> -->
      <li
          class="nav-item"
          v-bind:class="[{ active: this.$router.currentRoute.name == 'parcelas' }]"
      >
          <router-link class="nav-link" to="/parcelas">
              <img src="img/icon-menu-parcelas.png">
              <span>Parcelas</span>
          </router-link>
      </li>
      <li
          class="nav-item"
          v-bind:class="[{ active: this.$router.currentRoute.name == 'quitarcontrato' }]"
      >
          <router-link class="nav-link" to="/quitarcontrato">
              <img src="img/icon-menu-quitar.png">
              <span>Quitar Contrato</span>
          </router-link>
      </li>
      <li
          class="nav-item"
          v-bind:class="[{ active: this.$router.currentRoute.name == 'carnedigital' }]"
          v-if="hideItemMenu"
      >
          <router-link class="nav-link" to="/carnedigital">
              <img src="img/icon-menu-carne.png">
              <span>Carnê Digital</span>
          </router-link>
      </li>
      <li
          class="nav-item"
          v-bind:class="[{ active: this.$router.currentRoute.name == 'solicitarcarencia' }]"
          v-if="showSolicitarCarencia"
      >
          <router-link class="nav-link" to="/solicitacao-carencia">
              <img src="img/icon-menu-carencia.png">
              <span>Solicitar Carência</span>
          </router-link>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="ofertas-pre-aprovadas.html">
          <i class="fas fa-star"></i>
          <span>Ofertas Pré-aprovadas</span></a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" href="#">
          <i class="fas fa-comment"></i>
          <span>Chat de Contato</span></a>
      </li> -->
    </ul>
    </div>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

      <div class="content pt-4" style="display: none">
        <div class="container-fluid">
          <a id="btn-ofertas" href="#" class="btn btn-icon btn-primary d-sm-inline-block mr-auto ml-md-3 my-2 my-md-0 mw-100">
            <i class="fas fa-star"></i>
            <span class="text mx-2">Ofertas Pré-aprovadas</span>
          </a>
        </div>
      </div>

      <!-- Main Content -->
      <div id="content" class="mb-5">
          <router-view />
      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>© 2022 Banco Digimais S.A. - Todos os direitos reservados.</span>
          </div>
        </div>
      </footer>
      <!-- End of Footer -->

    </div>
    <!-- End of Content Wrapper -->

  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>

  </div>
</template>

<script>

// import EventBus from '../event-bus'
import AuthServiceContext from "../services/AuthService";
import LogoutModal from '../components/LogoutModal'
import AlterarSenhaModal from '../components/AlterarSenhaModal';
import AlterarEmailModal from '../components/AlterarEmailModal';
import EventBus from '../event-bus';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

import jQuery from 'jquery';
var $ = jQuery;


export default {
  name: "PortalDefault",
  components:{
    LogoutModal,
    AlterarSenhaModal,
    AlterarEmailModal,
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      sessionTime:'',
      usuario: {
        nome: '',
        nomeCompleto: '',
        email: ''
      },
      podeAcessarSolicitacaoCarencia: false,
      ehLoginCompleto: false,
      cpfcnpj: ''
    }
  },
  computed:{
    hideItemMenu() {
      return !this.authService.isFastAccess()
    },
    showSolicitarCarencia() {
      if (this.ehLoginCompleto){
        return !this.ehLoginCompleto;
      }
      else
        return this.podeAcessarSolicitacaoCarencia;
    },
    siteVersion() {
      let config = this.authService.config();
      return config.version || '';
    }
  },
  timers: {
    updateTimeSession: { time: 1000,  repeat: true, autostart: true }
  },
  methods: {
    updateTimeSession:function() {
      var expDate = this.authService.getExpDate();
      var d = expDate.diff(moment());
      if (d <= 0) {
        this.$router.push("/");
      }
      this.sessionTime = moment.duration(d).format("hh:mm:ss");
    },
    showAlterarSenha: function (event) {
      if (event) {
        event.preventDefault();
      }
      EventBus.$emit('eventShowAlterarSenha');
    },
    showAlterarEmail: function (event) {
      if (event) {
        event.preventDefault();
      }
      EventBus.$emit('eventShowAlterarEmail');
    },
    handleResize() {
      if($('body').width() < 768) {
        $('.sidebar .collapse').collapse('hide');
        // $('#brand-wrapper').insertBefore('#btn-ofertas');
        // $('#countdown').prependTo('# content');
        // $('#user-nav').prependTo('.sidebar');
        $('#userDropdown').attr('data-toggle','collapse');
        $('#dropdown-menu').removeClass('animated--grow-in');
      }
      if($('body').width() < 1200)
      {
        if( $("#contratos-tabs").length && $("#contratos-tabs .contrato-tab").length > 1 ){
          $("#contratos-tabs").appendTo('.dropdown.contratos .dropdown-menu');
          $('.dropdown.contratos').show();
        }  
      }
      else {
        // $('#brand-wrapper').prependTo('#top-navbar');
        // $('#user-nav').appendTo('#top-navbar');
        // $('#countdown').insertBefore('#top-navbar .navbar-nav');
        $('#userDropdown').attr('data-toggle','dropdown');
        $('#dropdown-menu').addClass('animated--grow-in');
        if( $("#contratos-tabs").length && $("#contratos-tabs .contrato-tab").length > 1 ){
          $("#contratos-tabs").insertBefore('#contrato-content');
          $('.dropdown.contratos').hide();
        }
      }
    }
  },
  mounted() {
    var self = this;

    self.ehLoginCompleto = localStorage.cpfCnpj != "";
    self.cpfcnpj = localStorage.cpfCnpj;

    if (!this.authService.isFastAccess())
    {
      this.authService.verificarAcessoCarencia((result) => {
        self.podeAcessarSolicitacaoCarencia = result;
      });
    }


    let userData = this.authService.userData();
    this.usuario.nome = userData.Nome;
    this.usuario.nomeCompleto = userData.NomeCompleto;
    this.usuario.email = userData.Email;
    
    this.handleResize();

    $("#sidebarToggle, #sidebarToggleTop").on('click', function() {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
      }
    });
    $(window).resize(function() {
      self.handleResize();
    });
  }
};
</script>

