<template>
  <div id="password-change-modal" class="modal fade modal-forms" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="margin:auto" role="document">
      <!--  -->
      <div class="modal-content" id="forgot-password-modal-content" v-if="exibeConfirmacao">
        <div class="modal-header pb-0">
          <h4 class="modal-title">Senha alterada com sucesso!</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">
            Sua senha foi alterada com sucesso. No próximo acesso ao portal de auto atentimento, utilize sua nova senha.
          </p>
        </div>
        <div class="modal-footer">
          <button
              type="submit"
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              v-on:click="fecharModal"
            >
            Fechar
          </button>
        </div>
      </div>
      <!--  -->
      <div class="modal-content" id="password-chance-modal-content" v-if="exibeForm">
        <div class="modal-header pb-0">
          <h4 class="modal-title text-uppercase">Atenção</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">
            Informe sua senha atual e uma nova senha para realizar a troca.
          </p>
          <form method="post" id="change-password-form" role="form">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-address-card"></i>
                </div>
                <input
                  name="senhaAtual"
                  type="password"
                  class="form-control input-lg"
                  placeholder="Senha Atual"
                  required
                  data-parsley-minlength="6"
                  data-parsley-trigger="keyup"
                  v-model="senhaAtual"
                />
              </div>
            </div>
            <div class="form-group mt-5">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-lock"></i>
                </div>
                <input
                  name="novaSenha"
                  type="password"
                  class="form-control input-lg"
                  placeholder="Digite sua senha"
                  required
                  data-parsley-minlength="6"
                  data-parsley-trigger="keyup"
                  v-model="novaSenha"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-lock"></i>
                </div>
                <input
                  name="confirmaSenha"
                  type="password"
                  class="form-control input-lg"
                  placeholder="Confirme sua senha"
                  required
                  data-parsley-equalto="#passwd"
                  data-parsley-trigger="keyup"
                  v-model="confirmaSenha"
                />
              </div>
            </div>
            <div v-if="errors.length">
              <ul>
                <li
                  class="text-danger"
                  v-for="(error, index)  in errors"
                  v-bind:key="index"
                >{{ error }}</li>
              </ul>
            </div>
            
            <button
              type="submit"
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              v-on:click="alteraSenha"
            >
              <div class="spinner-border" role="status" v-if="exibeLoading">
                <span class="sr-only"></span>
              </div>
              Alterar Senha</button>
          </form>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import EventBus from '../event-bus';
import AuthServiceContext from "../services/AuthService";

export default {
  name: "AlterarSenhaModal",
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      errors: [],
      processing: false,
      alteracaoRealizada: false,
      senhaAtual: "",
      novaSenha: "",
      confirmaSenha: ""
    };
  },
  mounted() {
    var self = this;
    EventBus.$on('eventShowAlterarSenha', function() {
      self.show();
    });
  },
  computed: {
    exibeLoading:function() {
      return this.processing;
    },
    exibeForm: function() {
      return !this.alteracaoRealizada;
    },
    exibeConfirmacao: function() {
      return this.alteracaoRealizada;
    }
  },
  methods: {
    show: function(event){
      if (event) {
        event.preventDefault();
      }
      this.senhaAtual = "";
      this.novaSenha = "";
      this.confirmaSenha = "";
      $("#password-change-modal").modal("show");
    },
    fecharModal: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.senhaAtual = "";
      this.novaSenha = "";
      this.confirmaSenha = "";
      $("#password-change-modal").modal("hide");
    },
    alteraSenha: function(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;

      this.errors = [];

      if (this.senhaAtual.length == 0) {
        this.errors.push("informe sua senha atual.");
      }

      if (this.novaSenha.length == 0) {
        this.errors.push("Informa sua senha.");
      } else if (this.novaSenha != this.confirmaSenha) {
        this.errors.push("Verifique a confirmação da senha.");
      } else if (!this.validPassword(this.novaSenha)) {
        this.errors.push(
          "A senha deve possuir entre 6 e 8 dígitos,  conter pelo ao menos uma letra maiúscula, uma minúscula e número."
        );
      }
      
      if (this.errors.length == 0) {
        let acessoApi = new ApiPortalAutoAtendimento.AcessoApi();
        let userData = this.authService.userData();

        acessoApi.alterarSenha(userData.Usuario, this.senhaAtual, this.novaSenha, (error, data, response) => {
          if (!error) {
            this.alteracaoRealizada = true;
          } else {
            if (response && response.text) {
              try {
                let listOfErrors = JSON.parse(response.text);
                listOfErrors.forEach(error => {
                  this.errors.push(error.message);
                });
              } catch (e) {
                this.errors.push(
                  "Erro ao solicitar redefinição de senha. Tente novamente."
                );
              }
            } else {
              this.errors.push(
                "Erro ao solicitar redefinição de senha. Tente novamente."
              );
            } 
          }
        });
      }
    },
    validPassword: function(value) {
      let re = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,8})/g;
      return re.test(value);
    },
  }
};
</script>